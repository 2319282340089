import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  InputBase,
  Checkbox,
  Select,
  Menu,
  MenuItem,
} from "@mui/material";
import axios from "axios";
import { appConfig } from "../../config.js";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import ConfirmButtons from "../widgets/ConfirmButtons.js";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import "./login.css";

const EditUser = ({ userId, setId, fetchUsers }) => {
  const theme = useTheme();
  const [user, setUser] = useState({});
  const [users, setUsers] = useState({});
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [approved, setApproved] = useState(false);
  const [banned, setBanned] = useState(false);
  const [role, setRole] = useState("");
  const [message, setMessage] = useState("");
  const NODEURL = appConfig.NODEURL;

  useEffect(() => {
    // Fetch the user details from the backend

    const fetchUser = async () => {
      try {
        const response = await axios.get(`${NODEURL}api/users/${userId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`, // Authorization header
            "Content-Type": "application/json", // Add content-type if sending JSON
          },
        });
        setUser(response.data);
        setApproved(response.data.approved);
        setBanned(response.data.banned);
        setRole(response.data.role);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching user data", error);
      }
    };

    fetchUser();
  }, [userId]);

  const handleDeleteUser = async (userId) => {
    // if (userId === id) {
    //   setId(0);
    // }
    try {
      await axios.delete(`${NODEURL}api/users/${userId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      });
      // setUsers(users.filter((user) => user._id !== userId));
      console.log("User deleted successfully");
      fetchUsers();
      setUser("");
    } catch (error) {
      console.error("Error deleting user", error);
    }
  };

  const handleUpdate = async () => {
    try {
      await axios.put(`${NODEURL}api/users/${userId}`, {
        password,
        approved,
        banned,
        role,
      });
      setMessage("User updated");
    } catch (error) {
      console.error("Error updating user", error);
      console.log("Error updating user");
    }
  };
  const emailRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  const sendReset = async () => {
    // console.log(email);
    const res = await axios.post(
      `${appConfig.NODEURL}api/auth/send-reset`,
      { email: user.email },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`, // Add the token to the Authorization header
        },
      }
    );
    // console.log(res.data);
    setMessage(res.data);
  };

  if (loading) return <p>Loading...</p>;

  const UpdateUser = () => {
    // const [password, setPassword] = useState("");
    // const [approved, setApproved] = useState(false);
    // const [banned, setBanned] = useState(false);
    // const [role, setRole] = useState(1);
    if (!user.username) {
      return null;
    }
    return (
      <div
        style={{
          // ...theme.typography.h6,
          display: "flex",
          // flexDirection: "column",
          flexWrap: "wrap",
          gap: 10,
          paddingTop: 10,
          backgroundColor: theme.palette.grey[300],
          border: "1px solid black",
          borderRadius: 10,
          marginTop: 20,
          boxShadow: theme.shadows[12],
          position: "relative",
        }}
      >
        <div
          style={{
            ...theme.typography.h6,
          }}
        >
          {user.username}
        </div>
        <div>
          <label>Email verified:</label>
          {user.email_verified ? "YES" : "NO"}
        </div>
        <div>
          <label>Password:</label>
          <Button
            onClick={sendReset}
            style={{
              ...theme.typography.label,
              backgroundColor: theme.palette.primary.main,
              // display: localStorage.getItem("token") ? "none" : "inline",
              display: emailRegex.test(user.email) ? "inline" : "none",
              height: "fit-content",
            }}
          >
            Send Reset
          </Button>
          <TextField
            style={{ backgroundColor: "#fff", display: "none" }}
            size="small"
            type="password"
            name="password"
            value={password}
            // onChange={(e) => setPassword(e.target.value)}
            onChange={handleUpdate}
          />
        </div>
        <div>
          <label>Approved:</label>
          <input
            type="checkbox"
            checked={approved}
            onChange={(e) => setApproved(e.target.checked)}
          />
        </div>
        <div>
          <label>Banned:</label>
          <input
            type="checkbox"
            checked={banned}
            onChange={(e) => setBanned(e.target.checked)}
          />
        </div>
        <div>
          <div style={{ display: "flex", gap: 10 }}>
            <label>Role:</label>
            <Select
              size="small"
              value={role}
              onChange={(e) => setRole(e.target.value)}
            >
              {[1, 2, 3, 4].map((r, i) => (
                <MenuItem key={i} value={r}>
                  {r}
                </MenuItem>
              ))}
            </Select>
            <div>{message}</div>
          </div>
          {/* <InputBase
        style={{ backgroundColor: "#fff", width: 60 }}
        type="number"
        value={role}
        onChange={(e) => setRole(e.target.value)}
      /> */}
        </div>

        <Button sx={theme.typography.button} onClick={handleUpdate}>
          Update User
        </Button>
        <ConfirmButtons
          label="delete"
          action={handleDeleteUser}
          args={user.id}
          icon={<DeleteForeverIcon />}
        ></ConfirmButtons>
        <Button
          sx={{
            ...theme.typography.littleLink,
            position: "absolute",
            top: 0,
            left: 0,
          }}
          onClick={() => setId("")}
        >
          <CloseIcon />
        </Button>
      </div>
    );
  };

  return (
    <div style={{ display: "flex" }}>
      <UpdateUser />
    </div>
  );
};

export default EditUser;
