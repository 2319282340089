import React, { useState, useEffect } from "react";
import axios from "axios";
import { Box, IconButton } from "@mui/material";

import { appConfig } from "../../config.js";

import { useTheme } from "@mui/material/styles";

import CloseIcon from "@mui/icons-material/Close";
import NavForm from "../NavForm.js";

const EditNavigationItem = ({ menuId, setMenuId, refresh }) => {
  const theme = useTheme();

  const [navItem, setNavItem] = useState({
    parent_id: "",
    label: "",
    url: "",
    position: 1,
    is_visible: true,
  });

  //   const [navigationOptions, setNavigationOptions] = useState([]); // Options for parent_id

  console.log("wt");

  const fetchNavItem = async () => {
    try {
      const response = await axios.get(
        `${appConfig.NODEURL}api/navigation/${menuId}`
      );
      setNavItem(response.data[0]);
    } catch (error) {
      console.error("Error fetching navigation item:", error);
    }
  };
  useEffect(() => {
    // const fetchNavigationOptions = async (menuId) => {
    //   try {
    //     const response = await axios.get(`${appConfig.NODEURL}api/navigation`);

    //     setNavigationOptions(
    //       response.data
    //         .filter((f) => f.parent_id === null)
    //         .filter((f) => f.id !== Number(menuId))
    //     );
    //   } catch (error) {
    //     console.error("Error fetching navigation options:", error);
    //   }
    // };

    fetchNavItem();
    // fetchNavigationOptions(menuId);
  }, [menuId]);

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNavItem((prev) => ({ ...prev, [name]: value }));
  };

  // Handle checkbox change for is_visible
  const handleCheckboxChange = (e) => {
    setNavItem((prev) => ({ ...prev, is_visible: e.target.checked }));
  };

  // Handle form submission to save changes
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`${appConfig.NODEURL}api/navigation/${menuId}`, navItem, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`, // Authorization header
          "Content-Type": "application/json", // Add content-type if sending JSON
        },
      });
      refresh();
      setMenuId(0);
    } catch (error) {
      console.error("Error updating navigation item:", error);
    }
  };

  if (!navItem) {
    return null;
  }
  return (
    <div style={{ position: "relative" }}>
      <Box
        sx={
          {
            //   margin: 1,
            // display: "flex",
            // gap: 5,
            // padding: 1,
            // borderRadius: 5,
            // justifyContent: "center",
            // alignItems: "center",
            // width: "fit-content",
            // //   maxWidth: 600,
            // //   margin: "",
            // border: `1px solid ${theme.palette.primary.main}`,
            // backgroundColor: theme.palette.grey[100],
          }
        }
      >
        <div>
          <IconButton
            style={{
              position: "absolute",
              left: 0,
              top: -20,
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
            }}
            onClick={() => setMenuId(0)}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <NavForm
          handleCheckboxChange={handleCheckboxChange}
          handleInputChange={handleInputChange}
          handleSubmit={handleSubmit}
          menu={navItem}
          text="Save Changes"
        />
      </Box>
    </div>
  );
};

export default EditNavigationItem;
