import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  //  TextField,
  Button,
  IconButton,
  Box,
  InputBase,
  // Checkbox,
  FormControlLabel,
  // InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CloseIcon from "@mui/icons-material/Close";

import "./dancers.css";
import { appConfig } from "../../config.js";
import ConfirmButtons from "../widgets/ConfirmButtons.js";
import BlocksCrud from "./BlocksCrud.js";
import { BackspaceOutlined } from "@mui/icons-material";
//import RichTextEditor from "./RichTextEditor.js";

const CuteCms = () => {
  const theme = useTheme();
  const [pages, setPages] = useState([]);
  const [id, setId] = useState(0);

  const fetchPages = async () => {
    console.log("fetchPage");
    try {
      const response = await axios.get(`${appConfig.NODEURL}api/pages`, null, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`, // Authorization header
          "Content-Type": "application/json", // Add content-type if sending JSON
        },
      });
      setPages(response.data);
    } catch (error) {
      console.error("Error fetching pages", error);
    }
  };

  useEffect(() => {
    fetchPages();
  }, []);

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${appConfig.NODEURL}api/pages/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`, // Authorization header
          "Content-Type": "application/json", // Add content-type if sending JSON
        },
      });
      console.log("Page deleted successfully!");
      // Refresh page list (simplest way)
      window.location.reload();
    } catch (error) {
      console.error("Error deleting page", error);
    }
  };

  const CreateNewPage = ({ pageId }) => {
    const [title, setTitle] = useState("");
    const [slug, setSlug] = useState("");
    const [content, setContent] = useState("{}"); // This can be your rich text content (or blocks)

    if (pageId > 0) {
      return null;
    }

    const handleSubmit = async (e) => {
      e.preventDefault();
      const pageData = { title, slug, content };

      try {
        const resp = await axios.post(
          `${appConfig.NODEURL}api/pages`,
          pageData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`, // Authorization header
              "Content-Type": "application/json", // Add content-type if sending JSON
            },
          }
        );
        // console.log("Page created successfully!");
        // console.log(resp);
        setId(resp.data.id);
        fetchPages();
      } catch (error) {
        console.error("Error creating page", error);
      }
    };
    return (
      <Box
        style={{
          display: id ? "block" : "block",
          backgroundColor: theme.palette.grey[300],
          width: "fit-content",
        }}
      >
        <form
          style={{
            display: "flex",
            // flexDirection: "column",
            // backgroundColor: "blue",
            // justifyContent: "space-evenly",
            alignItems: "center",
            gap: 10,
            flexWrap: "wrap",
          }}
          onSubmit={handleSubmit}
        >
          <div>
            <InputBase
              placeholder="Title"
              className="textField"
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </div>

          <div>
            <InputBase
              placeholder="slug"
              className="textField small"
              type="text"
              value={slug}
              onChange={(e) => setSlug(e.target.value)}
              required
            />
          </div>

          <Button style={theme.typography.button} type="submit">
            Add
          </Button>
        </form>
      </Box>
    );
  };

  const EditPage = ({ pageId }) => {
    const theme = useTheme();

    // Single state for all fields
    const [pageData, setPageData] = useState({
      title: "",
      slug: "",
      content: "",
      is_published: false,
      meta_title: "",
      meta_description: "",
      meta_keywords: "",
    });

    useEffect(() => {
      const fetchPage = async () => {
        try {
          const response = await axios.get(
            `${appConfig.NODEURL}api/pages/${pageId}`
          );
          const {
            title,
            slug,
            content,
            is_published,
            meta_title,
            meta_description,
            meta_keywords,
          } = response.data;
          setPageData({
            title,
            slug,
            content,
            is_published,
            meta_title,
            meta_description,
            meta_keywords,
          });
        } catch (error) {
          console.error("Error fetching page", error);
        }
      };
      if (pageId) fetchPage();
    }, [pageId]);

    const handleInputChange = (e) => {
      const { name, value, type, checked } = e.target;
      setPageData((prevState) => ({
        ...prevState,
        [name]: type === "checkbox" ? checked : value,
      }));
    };

    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
        await axios.put(`${appConfig.NODEURL}api/pages/${pageId}`, pageData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        });
        console.log("Page updated successfully!");
        fetchPages();
      } catch (error) {
        console.error("Error updating page", error);
      }
    };

    if (!pageId) {
      return null;
    }

    return (
      <Box style={{ display: pageId ? "block" : "none" }}>
        pageId {pageId}
        <div
        // style={{
        //   display: "flex",
        //   flexWrap: "wrap",
        //   gap: 10,
        //   // justifyContent: "start",
        //   alignItems: "center",
        //   border: "1px solid black",
        //   width: "fit-content",
        //   borderRadius: 5,
        //   position: "relative",
        // }}
        ></div>
        <form
          style={{
            width: "fit-content",
            display: "flex",
            flexWrap: "wrap",
            borderRadius: 10,
            backgroundColor: theme.palette.grey[300],
            // alignItems: "center",
            gap: 10,
          }}
          onSubmit={handleSubmit}
        >
          <IconButton
            style={{
              // backgroundColor: theme.palette.primary.main,
              // color: theme.palette.primary.contrastText,
              // position: "absolute",
              left: 0,
              top: 0,
            }}
            onClick={() => setId(0)}
          >
            <BackspaceOutlined />
          </IconButton>
          <div>
            <label>Title</label>
            <InputBase
              className="textField"
              type="text"
              name="title"
              value={pageData.title}
              onChange={handleInputChange}
              required
            />
          </div>
          <div>
            <label>Slug</label>
            <InputBase
              className="textField small"
              type="text"
              name="slug"
              value={pageData.slug}
              onChange={handleInputChange}
              required
            />
          </div>
          <div style={{ display: "none" }}>
            <label>Content</label>
            <textarea
              name="content"
              value={JSON.stringify(pageData.content, null, 3)}
              onChange={handleInputChange}
              required
            />
          </div>
          <div>
            <label>Published</label>
            <input
              type="checkbox"
              name="is_published"
              checked={pageData.is_published}
              onChange={handleInputChange}
            />
          </div>
          <div>
            <label>Meta Title</label>
            <InputBase
              className="textField"
              type="text"
              name="meta_title"
              value={pageData.meta_title}
              onChange={handleInputChange}
            />
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <label>Meta Description</label>
            <textarea
              name="meta_description"
              value={pageData.meta_description}
              onChange={handleInputChange}
            />
          </div>
          <div>
            <label>Meta Keywords</label>
            <InputBase
              className="textField small"
              type="text"
              name="meta_keywords"
              value={pageData.meta_keywords}
              onChange={handleInputChange}
            />
          </div>
          <button type="submit">Save Changes</button>

          <ConfirmButtons
            icon={<DeleteForeverIcon />}
            action={handleDelete}
            args={pageId}
          />
          <pre style={{ display: "none" }}>
            {JSON.stringify(pageData, null, 3)}
          </pre>
        </form>
      </Box>
    );
  };

  const List = ({ pageId }) => {
    if (pageId > 0) {
      // return null;
    }
    let menuItems = [];
    // return null;
    return (
      <div>
        <Box
          sx={{
            display: {
              xs: "none", // Show on extra small screens
              sm: "none", // Show on small screens
              md: "none", // Show on medium screens
              lg: "flex", // Hide on large screens
              xl: "flex", // Hide on extra large screens
            },

            flexWrap: "wrap",
            gap: 1,
          }}
        >
          {/* <div style={{ display: "flex", flexWrap: "wrap", gap: 10 }}> */}
          {pages.map((page, index) => {
            menuItems.push(
              <MenuItem key={index} value={page.id}>
                {page.title}
              </MenuItem>
            );
            const topage = `/editpage/${page.id}`;
            return (
              <div
                onClick={() => setId(page.id)}
                key={index}
                style={{
                  cursor: "pointer",
                  display: "flex",
                  gap: 20,
                  // height: "5em",
                  // paddingTop: 5,
                  alignItems: "center",
                  justifyContent: "space-evenly",
                  border: "1px solid black",
                  padding: 3,
                  borderRadius: 10,
                  backgroundColor:
                    page.id === id ? theme.palette.grey[300] : "#fff",
                  // width: 320,
                }}
              >
                <div style={{ ...theme.typography.h6 }}>{page.title}</div>
                {/* {page.title} - <Link to={topage}>view</Link>{" "} */}
                <IconButton onClick={() => setId(page.id)}>
                  <EditIcon />
                </IconButton>
              </div>
            );
          })}
        </Box>
        <Box
          sx={{
            display: {
              xs: "block", // Show on extra small screens
              sm: "block", // Show on small screens
              md: "block", // Show on medium screens
              lg: "none", // Hide on large screens
              xl: "none", // Hide on extra large screens
            },
          }}
        >
          ControlLabel labelPlacement="start" label=
          <label
            style={{
              ...theme.typography.h5,
              display: id === 0 ? "none" : "block",
              textTransform: "capitalize",
            }}
          >
            Pages
          </label>
          control=
          {
            <Select
              onChange={(event) => setId(event.target.value)}
              value={id}
              // onClick={() => setId(pages.id)}
            >
              <MenuItem value={0}>
                <div style={theme.typography.h5}>Pages...</div>
              </MenuItem>
              {menuItems}
            </Select>
          }
        </Box>
      </div>
    );
  };
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
      <CreateNewPage pageId={id} />
      <List pageId={id} />
      <EditPage pageId={id} />
      <BlocksCrud pageId={id} />
    </div>
  );
};

export default CuteCms;
