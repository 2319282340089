import React, { useState } from "react";
import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Heading from "@tiptap/extension-heading";
import TextStyle from "@tiptap/extension-text-style";
import Color from "@tiptap/extension-color";
import Underline from "@tiptap/extension-underline";
import Table from "@tiptap/extension-table";
import TableRow from "@tiptap/extension-table-row";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import pretty from "pretty";
import { useTheme } from "@mui/material/styles";
import bgColor from "./bgColor.ts";
import CustomDiv from "./CustomDiv.js";
import { appConfig } from "../../config.js";
import "./rte.css";

const TipTapEditor = () => {
  const NODEURL = appConfig.NODEURL;
  const theme = useTheme();
  const [isSourceView, setSourceView] = useState(false);
  const [sourceContent, setSourceContent] = useState(""); // For source view content
  const [colorText, setColorText] = useState(true);

  // Update sourceContent when switching to source view
  const toggleSourceView = () => {
    if (!isSourceView) {
      // Switching to source view: Save editor content to state
      setSourceContent(editor.getHTML()); // Or use `editor.getJSON()` for JSON
    } else {
      // Switching back to editor view: Update editor content with source content
      editor.commands.setContent(sourceContent); // Apply the content
    }
    setSourceView(!isSourceView); // Toggle the view
  };

  const handleSourceChange = (e) => {
    setSourceContent(e.target.value); // Update the state for the textarea
  };

  const handleSubmit = () => {
    const jsonData = editor.getJSON(); // JSON format for the content column
    const htmlData = editor.getHTML(); // HTML format for the markup column

    // Send data to the server
    fetch(`${NODEURL}api/blocks`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        content: jsonData,
        markup: htmlData,
      }),
    });
  };

  const toggleBold = () => {
    editor.chain().focus().toggleBold().run();
  };

  const toggleItalic = () => {
    editor.chain().focus().toggleItalic().run();
  };

  const toggleUnderline = () => {
    editor.chain().focus().toggleUnderline().run();
  };

  const editor = useEditor({
    extensions: [
      CustomDiv,
      bgColor,
      StarterKit,
      Underline,
      // Heading.configure({
      //   levels: [1, 2, 3, 4, 5, 6],
      // }),
      TextStyle,
      Color,
      Table.configure({
        resizable: true,
      }),
      TableRow,
      TableCell,
      TableHeader,
    ],
    content: "<p>Hello, World!</p>",
  });

  if (!editor) {
    return null;
  }

  //   const toggleSourceView = () => {
  //     setSourceView(!isSourceView);
  //   };

  return (
    <div>
      <div className="toolbar">
        <button onClick={toggleBold}>
          {editor.isActive("bold") ? "unbold" : "bold"}
        </button>
        <button onClick={toggleItalic}>
          {editor.isActive("italic") ? "un-italic" : "italic"}
        </button>
        <button onClick={toggleUnderline}>
          {editor.isActive("underline") ? "un-underline" : "underline"}
        </button>
        <button
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 1 }).run()
          }
        >
          H1
        </button>
        <button
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 2 }).run()
          }
        >
          H2
        </button>
        <button
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 3 }).run()
          }
        >
          H3
        </button>

        <input
          type="checkbox"
          onChange={() => setColorText((prev) => !prev)}
          checked={colorText}
        />
        {Object.entries(theme.palette).map((palette, index) => {
          if (palette[1].main) {
            return (
              <div key={index}>
                <pre style={{ display: "none" }}>
                  {JSON.stringify(palette, null, 3)}
                </pre>
                <button
                  style={{
                    color: colorText
                      ? palette[1].main
                      : palette[1].contrastText,
                    backgroundColor: colorText
                      ? palette[1].contrastText
                      : palette[1].main,
                  }}
                  key={index}
                  onClick={() =>
                    editor
                      .chain()
                      .focus()
                      .setColor(
                        colorText ? palette[1].main : palette[1].contrastText
                      )
                      //   .backgroundColor("#000000")
                      .setBackColor(
                        colorText ? palette[1].contrastText : palette[1].main
                      )
                      .run()
                  }
                >
                  {palette[0]}
                </button>
              </div>
            );
          }
        })}
        <button
          onClick={() =>
            editor.chain().focus().insertTable({ rows: 2, cols: 2 }).run()
          }
        >
          Insert Table
        </button>
        <button onClick={() => editor.chain().focus().addColumnBefore().run()}>
          Add Column Before
        </button>
        <button onClick={() => editor.chain().focus().addRowAfter().run()}>
          Add Row After
        </button>

        <button onClick={() => editor.chain().focus().deleteColumn().run()}>
          Delete Column
        </button>
        <button onClick={() => editor.chain().focus().deleteRow().run()}>
          Delete Row
        </button>

        <button onClick={toggleSourceView}>
          {isSourceView ? "Editor View" : "Source View"}
        </button>
      </div>

      {!isSourceView ? (
        <EditorContent editor={editor} />
      ) : (
        <textarea
          style={{ width: "100%", height: "400px" }}
          value={pretty(sourceContent)}
          onChange={handleSourceChange} // Capture changes in source view
        />
      )}
      <button onClick={handleSubmit}>Submit</button>
    </div>
  );
};

export default TipTapEditor;
