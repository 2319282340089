import React, { useState, useEffect } from "react";
import axios from "axios";
import { useTheme } from "@mui/material/styles";
import { appConfig } from "../../config";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Zoom from "@mui/material/Zoom";
import ConfirmButtons from "../widgets/ConfirmButtons.js";
import "./playlist.css";

const PlaylistForm = () => {
  const theme = useTheme();

  const [items, setItems] = useState([]);
  const [newItem, setNewItem] = useState({ url: "", label: "", position: 1 });
  // const playlistId = match?.params?.id || 0;
  const [playlistId, setPlaylistId] = useState(0);

  const [playlists, setPlaylists] = useState([]);

  const [newname, setNewname] = useState("");

  const fetchPlaylists = async () => {
    try {
      const response = await axios.get(`${appConfig.NODEURL}api/playlists`);

      setPlaylists(response.data);
    } catch (error) {
      console.error("Error fetching playlists", error);
    }
  };

  const fetchPlaylist = async () => {
    try {
      const response = await axios.get(
        `${appConfig.NODEURL}api/playlists/${playlistId}`
      );
      setNewname(response.data.playlist.name);
      setItems(
        response.data.items.sort((a, b) => {
          if (a.position < b.position) {
            return -1;
          }
          if (a.position > b.position) {
            return 1;
          }
          return 0;
        })
      );
    } catch (error) {
      console.error("Error fetching playlist", error);
    }
  };

  useEffect(() => {
    fetchPlaylists();
  }, []);

  useEffect(() => {
    if (playlistId) {
      fetchPlaylist();
    }
  }, [playlistId]);

  const handleAddItem = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${appConfig.NODEURL}api/playlists/${playlistId}/items`,
        newItem,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`, // Authorization header
            "Content-Type": "application/json", // Add content-type if sending JSON
          },
        }
      );
      setItems(
        [...items, response.data].sort((a, b) => {
          if (a.position < b.position) {
            return -1;
          }
          if (a.position > b.position) {
            return 1;
          }
          return 0;
        })
      );
      setNewItem({ url: "", label: "" });
    } catch (error) {
      console.error("Error adding item", error);
    }
  };

  // const handleUpdate = async (playlist) => {
  //   // const data = { name };
  //   await axios.put(
  //     `${appConfig.NODEURL}api/playlists/${playlistId}`,
  //     playlist,
  //     {
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem("token")}`, // Authorization header
  //         "Content-Type": "application/json", // Add content-type if sending JSON
  //       },
  //     }
  //   );
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = { name: newname };
    await axios.post(`${appConfig.NODEURL}api/playlists`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`, // Authorization header
        "Content-Type": "application/json", // Add content-type if sending JSON
      },
    });
    // alert("Playlist saved successfully!");
    fetchPlaylists();
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${appConfig.NODEURL}api/playlists/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`, // Authorization header
          "Content-Type": "application/json", // Add content-type if sending JSON
        },
      });
      //alert("Playlist deleted successfully!");
      // setPlaylists(playlists.filter((playlist) => playlist.id !== id));
      fetchPlaylists();
    } catch (error) {
      console.error("Error deleting playlist", error);
    }
  };

  const handleItemChange = async (event, id) => {
    const value = event.target.value;
    const field = event.target.name;
    setItems((prevItems) =>
      prevItems.map((item) =>
        item.id === id ? { ...item, [field]: value } : item
      )
    );
  };

  const deletePlaylistItem = async (event) => {
    try {
      await axios.delete(
        `${appConfig.NODEURL}api/playlists/items/${event.target.name}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`, // Authorization header
            "Content-Type": "application/json", // Add content-type if sending JSON
          },
        }
      );
      console.log("Page deleted successfully!");
      fetchPlaylist();
    } catch (error) {
      console.error("Error deleting page", error);
    }
  };

  const updatePlaylistItem = async (item) => {
    try {
      const response = await axios.put(
        `${appConfig.NODEURL}api/playlists/${item.id}/items`,
        {
          url: item.url,
          label: item.label,
          position: item.position,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`, // Authorization header
            "Content-Type": "application/json", // Content-type header for JSON
          },
        }
      );
      fetchPlaylist();
      // setPlaylists(response.data);
    } catch (error) {
      console.error("Error fetching playlists", error);
    }
  };

  // setBlock((prev) => ({ ...prev, [event.target.name]: event.target.value }))
  const [show, setShow] = useState("false");

  const PlayLists = ({ fetchPlaylists, _name, playlistId }) => {
    const [name, setName] = useState(_name);

    const handleUpdate = async (playlist) => {
      // const data = { name };
      console.log(playlist);
      console.log(name);
      try {
        await axios.put(
          `${appConfig.NODEURL}api/playlists/${playlistId}`,
          { ...playlist, name: name },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`, // Authorization header
              "Content-Type": "application/json", // Add content-type if sending JSON
            },
          }
        );
        fetchPlaylists();
        fetchPlaylist();
      } catch (err) {}
    };

    return (
      <div>
        <div
          style={{
            ...theme.typography.h6,
            display: playlistId ? "none" : "block",
          }}
        >
          Playlists
        </div>
        <ul>
          {playlists.map((playlist) => {
            return (
              <li
                key={playlist.id}
                style={{
                  display: "flex",
                  gap: 10,
                  alignItems: "center",
                  backgroundColor:
                    playlist.id === playlistId
                      ? theme.palette.grey[300]
                      : "#fff",
                  width: "fit-content",
                  margin: 5,
                  padding: 5,
                  borderRadius: 5,
                }}
              >
                {playlistId === playlist.id && (
                  <div>
                    <button onClick={() => setPlaylistId("")}> back</button>
                    <ConfirmButtons
                      icon={<DeleteForeverIcon />}
                      action={handleDelete}
                      args={playlist.id}
                    />
                  </div>
                )}
                {playlistId === playlist.id ? (
                  <input
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                  />
                ) : (
                  <a
                    style={{
                      cursor: "pointer",
                      border: "1px solid black",
                      padding: 5,
                      borderRadius: 5,
                      display: playlistId ? "none" : "block",
                    }}
                    onClick={() => setPlaylistId(playlist.id)}
                  >
                    {playlist.name}
                  </a>
                )}
                {playlistId === playlist.id && (
                  <div>
                    <button onClick={() => handleUpdate(playlist)}>
                      update
                    </button>
                    <button onClick={() => setPlaylistId("")}>cancel</button>
                  </div>
                )}
              </li>
            );
          })}
        </ul>
      </div>
    );
  };
  return (
    <div>
      <div
        style={{
          display: "flex",
          backgroundColor: playlistId ? theme.palette.grey[300] : "#fff",
        }}
      >
        <form onSubmit={handleSubmit}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <label>New List</label>
            <input
              value={newname}
              onChange={(e) => setNewname(e.target.value)}
              required
            />
            <button type="submit">ADD</button>
          </div>
        </form>
      </div>

      <PlayLists
        fetchPlaylists={fetchPlaylists}
        fetchPlaylist={fetchPlaylist}
        _name={newname}
        playlistId={playlistId}
      />

      {playlistId && (
        <Zoom in={true}>
          <div>
            <form onSubmit={handleAddItem}>
              <input
                type="text"
                value={newItem.label}
                placeholder="Label"
                onChange={(e) =>
                  setNewItem({ ...newItem, label: e.target.value })
                }
                required
              />
              <input
                type="text"
                value={newItem.url}
                placeholder="Video URL"
                onChange={(e) =>
                  setNewItem({ ...newItem, url: e.target.value })
                }
                required
              />

              <label>order</label>
              <input
                style={{ width: 50 }}
                type="number"
                value={newItem.position}
                placeholder=""
                onChange={(e) =>
                  setNewItem({ ...newItem, position: e.target.value })
                }
                required
              />
              <button type="submit">Add Item</button>
            </form>

            <ul>
              {items.map((item) => (
                <li key={item.id}>
                  <input
                    name="label"
                    type="text"
                    value={item.label}
                    onChange={(e) => handleItemChange(e, item.id)}
                  />
                  <input
                    name="url"
                    type="text"
                    value={item.url}
                    onChange={(e) => handleItemChange(e, item.id)}
                  />
                  <label>order</label>
                  <input
                    name="position"
                    type="number"
                    value={item.position}
                    style={{ width: 50 }}
                    onChange={(e) => handleItemChange(e, item.id)}
                  />
                  <button
                    name={item.id}
                    onClick={() => updatePlaylistItem(item)}
                  >
                    update
                  </button>
                  <button name={item.id} onClick={deletePlaylistItem}>
                    delete
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </Zoom>
      )}
    </div>
  );
};

export default PlaylistForm;
