import React, { useState, useEffect } from "react";
import axios from "axios";
import { appConfig } from "../config.js";
import { Visibility } from "@mui/icons-material";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Select,
} from "@mui/material";

import { useTheme } from "@mui/material/styles";

const NavForm = ({
  handleSubmit,
  handleInputChange,
  handleCheckboxChange,
  menu,
  text,
}) => {
  const theme = useTheme();
  const [navigationOptions, setNavigationOptions] = useState([]); // Options for parent_id

  useEffect(() => {
    // Fetch available navigation options for the parent_id dropdown
    const fetchNavigationOptions = async () => {
      try {
        const response = await axios.get(
          `${appConfig.NODEURL}api/navigation`,
          null,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`, // Authorization header
              "Content-Type": "application/json", // Add content-type if sending JSON
            },
          }
        );
        setNavigationOptions(
          response.data
            .filter((f) => f.parent_id === null)
            .filter((f) => f.id !== Number(menu.id))
        );
      } catch (error) {
        console.error("Error fetching navigation options:", error);
      }
    };

    fetchNavigationOptions();
  }, [menu.id]);

  return (
    <form
      style={{
        width: "fit-content",
        borderRadius: 10,
        border: "1px solid black",
        backgroundColor: theme.palette.grey[100],
        display: "flex",
        alignItems: "center",
        gap: 10,
      }}
      name="NavForm"
      onSubmit={handleSubmit}
    >
      <TextField
        placeholder="Menu Name"
        size="small"
        name="label"
        value={menu.label}
        onChange={handleInputChange}
        required
      />

      {/* Parent Navigation */}

      <Select
        style={{ width: 200 }}
        size="small"
        name="parent_id"
        value={menu.parent_id ? menu.parent_id : "Top-level Menu"}
        onChange={handleInputChange}
      >
        <MenuItem value={"Top-level Menu"}>Top-level Menu</MenuItem>
        {navigationOptions.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            {item.label}
          </MenuItem>
        ))}
      </Select>

      {/* Position */}
      <label>Position</label>
      <TextField
        //   label="Position"
        size="small"
        name="position"
        type="number"
        value={menu.position}
        onChange={handleInputChange}
        required
        sx={{ marginRight: 2, width: 80 }}
        InputProps={{
          inputProps: {
            max: 100,
            min: 1,
            step: 1,
          },
        }}
      />
      {/* Visibility */}
      {/* <div style={{ marginRight: 5, display: "inline" }}>
        {menu.is_visible ? <Visibility /> : <VisibilityOffIcon />}
      </div> */}
      <FormControlLabel
        control={
          <Checkbox
            checked={menu.is_visible ? menu.is_visible : false}
            onChange={handleCheckboxChange}
            name="is_visible"
            title="toggle visibility"
          />
        }
        label={menu.is_visible ? <Visibility /> : <VisibilityOffIcon />}
        labelPlacement="left"
      />

      <Button type="submit" variant="contained">
        {text}
      </Button>

      <pre style={{ display: "none" }}>{JSON.stringify(menu, null, 3)}</pre>
    </form>
  );
};

export default NavForm;
